<template>
    <div class="profile--outer-container">
        <div class="profile--container">
            <div class="form--container">
                <div class="profile-form--container">
                    <div class="head--container">
                        <alert-text class="alert__text" :value="alertText" v-if="alertText.length > 0" size="lg" :success="isSuccess"></alert-text>
                        <div class="title--container">
                            <p>Персональные данные</p>
                        </div>
                    </div>
                    <div class="body--container">
                        <div class="input--container">
                            <profile-input placeholder="Имя" size="md" v-model="firstName" :is-trim-enable="true"></profile-input>
                        </div>
                        <div class="input--container">
                            <profile-input placeholder="Фамилия" size="md" v-model="lastName" :is-trim-enable="true"></profile-input>
                        </div>
                    </div>
                </div>
                <div class="profile-form--container">
                    <div class="head--container">
                        <div class="title--container">
                            <p>Контактные данные</p>
                        </div>
                    </div>
                    <div class="body--container">
                        <div class="input--container">
                            <profile-input id="phone" type="tel" placeholder="Номер телефона" size="md" v-model="phone" :is-trim-enable="true"></profile-input>
                        </div>
                        <div class="input--container">
                            <profile-input placeholder="Email" size="md" v-model="email" :is-trim-enable="true"></profile-input>
                        </div>
                    </div>
                </div>
                <div class="profile-form--container">
                    <div class="head--container">
                        <div class="title--container">
                            <p>Логин</p>
                        </div>
                    </div>
                    <div class="body--container">
                        <div class="input--container">
                            <profile-input placeholder="логин" size="md" v-model="login" :is-trim-enable="true" :is-russian-symbols-disabled="true" :value="login"></profile-input>
                        </div>
                    </div>
                </div>
                <div class="password--container">
                    <div class="head--container">
                        <div class="title--container">
                            <p>Сменить пароль</p>
                        </div>
                    </div>
                    <div class="body--container">
                        <div class="input--container">
                            <profile-input type="password" placeholder="Пароль" size="md" v-model="password" :is-trim-enable="true" icon-class="icon-hide"></profile-input>
                        </div>
                        <div class="input--container">
                            <profile-input type="password" placeholder="Повторите пароль" size="md" v-model="repeatPassword" :is-trim-enable="true" icon-class="icon-hide"></profile-input>
                        </div>
                    </div>
                </div>

            </div>
            <div class="controls--container" v-if="isChanged">
                <standart-button value="Сохранить изменения" style-button="standart-button" @click.native="updateProfile"></standart-button>
                <a @click="reset">Отмена</a>
            </div>
            <warning-update-profile-modal v-on:confirm="checkUpdateProfile"></warning-update-profile-modal>

        </div>

    </div>
</template>

<script>
    import ProfileInput from "@/components/input/ProfileInput";
    import StandartButton from "@/components/button/StandartButton";
    import IMask from "imask";
    import AlertText from "@/components/alert/AlertText";
    import WarningUpdateProfileModal from "@/components/modal/WarningUpdateProfileModal";
    export default {
        name: "SettingsProfileView",
        components: {WarningUpdateProfileModal, AlertText, StandartButton, ProfileInput},
        data(){
            return {
                login: "",
                email: "",
                phone: "",
                password: "",
                repeatPassword: "",
                alertText: "",
                isSuccess: false,
                mask: null,
                isChanged : false,
                firstName : '',
                lastName : ''
            }
        },
        watch:{
            login:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            email:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            phone:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            password:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            repeatPassword:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            firstName:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            },
            lastName:{
                deep:true,
                immediate: true,
                handler: function(newVal){
                    this.checkData()
                }
            }
        },
        methods:{

            isValidEmail(email){
                const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },
            async updateProfile(){
                const profile = this.$store.getters.PROFILE
                if (this.firstName.length <= 1){
                    this.alertText = "Введите корректное имя"

                } else if (this.lastName.length <= 1){
                    this.alertText = "Введите корректную фамилию"
                }
                else if  (this.login.length === 0){
                    this.alertText = "Введите корректный логин"
                } else if (this.email.length === 0 || !this.isValidEmail(this.email)){
                    this.alertText = "Введите корректный адрес электронной почты"
                } else if (this.phone.length !== 16){
                    this.alertText = "Введите корректный номер телефона"
                } else if ((this.password.length > 0) &&(this.password.length < 6)){
                    this.alertText = "Длина пароля не должна быть менее 6 символов"
                } else if (this.password !== this.repeatPassword) {
                    this.alertText = "Пароли не совпадают"
                } else {
                    if (profile.userName !== this.login){
                        await this.$store.dispatch("CHECK_LOGIN_EXISTS",{login : this.login})
                        if ( this.$store.getters.IS_EXISTS_LOGIN){
                            this.alertText = "Ошибка! Логин занят"
                        } else {
                            if ((this.password.length > 0) || (profile.userName !== this.login)){
                                await this.$modal.show("WarningUpdateProfileModal")
                            } else {
                                await this.update(profile)
                            }
                        }
                    } else {
                        if ((this.password.length > 0) || (profile.userName !== this.login)){
                            await this.$modal.show("WarningUpdateProfileModal")
                        } else {
                            await this.update(profile)
                        }
                    }
                }
            },

            async update(profile){
                try{
                    const data = {
                        login : (profile.userName === this.login) ? "" : this.login,
                        email : (profile._email === this.email) ? "" : this.email,
                        phone : (profile._phone === this.phone) ? "" : this.phone,
                        password : this.password,
                        firstName : (profile._firstName === this.firstName) ? "" : this.firstName,
                        lastName : (profile._lastName === this.lastName) ? "" : this.lastName,
                    }

                    await this.$store.dispatch("UPDATE_MY_PROFILE",{dataObject : data})
                    await this.$store.dispatch("UPDATE_LOCAL_PROFILE")
                    this.password = ""
                    this.repeatPassword = ""
                    this.checkData()
                    this.isSuccess = true
                    this.alertText = "Данные сохранены успешно"
                    setTimeout(() => {
                        this.isSuccess = false
                        this.alertText = ""
                    },2000)
                } catch (e) {
                    this.alertText("что-то пошло не так")
                }
            },

            reset(){
                // const profile = this.$store.getters.PROFILE
                // this.login = profile.userName
                // this.email = profile._email
                // document.getElementById("phone").value = profile._phone
                // this.phone = profile._phone
                this.init()
                this.password = ""
                this.repeatPassword = ""
            },

            async checkUpdateProfile(){
                const profile = this.$store.getters.PROFILE
                await this.update(profile)
            },

            async exit(){
              await this.$store.dispatch("EXIT_FROM_ACCOUNT");

                await this.$router.push({name: "NewAuth"})
            },


            checkData(){
                const profile = this.$store.getters.PROFILE
                if (profile == null) return;
                this.isChanged = !(profile._lastName === this.lastName && profile._firstName === this.firstName && profile.userName === this.login && profile._email === this.email && profile._phone === this.phone && this.password.length === 0 && this.repeatPassword.length === 0)
            },


            async init(){


                await this.$store.dispatch("GET_PROFILE")
                const profile = this.$store.getters.PROFILE
                this.login = profile.userName
                this.email = profile._email
              profile._phone = profile._phone.substring(1)
                document.getElementById("phone").value = profile._phone
                this.phone = profile._phone
                this.firstName = profile._firstName
                this.lastName = profile._lastName

            }
        },



        async mounted(){
            await this.init()
        }
    }
</script>

<style scoped lang="scss">

    .profile--outer-container{
        @include for-desktop-up{
            margin-left: 40px;
            margin-right: 0;
        }

        @include for-big-desktop-up{
            margin-left: 60px;
            margin-top: 40px;
            margin-right: 0;
        }

        @include for-desktop-devices{
            width: 382px;
            height: 740px;
            border-radius: 4px;
            background: #f8f8f8;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
        }
    }


    .profile--container{
        margin-top: 20px;
        width: 335px;
        height: 692px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 27px;

        @include for-phone-only{
            width: 335px;
            height: 564px;
        }

        @include for-phone-landscape-up{
            width: 335px;
            height: 578px;
        }

        @include for-tablet-up{
            width: 480px;
            height: 598px;
        }

        @include for-desktop-up{
            width: 335px;
            height: 491px;
            margin-right: 0;
        }

        @include for-big-desktop-up{
            width: 335px;
            height: 491px;
            margin-right: 0;
        }

        @include for-desktop-devices{
            margin-right: auto;
            margin-left: auto;
            display: flex;
            flex-direction: column;
        }


        .form--container{
            margin-top: 30px;
            width: 100%;

            @include for-desktop-devices{

                margin-top: 15px;
            }

            .head--container{

                .alert__text{
                    margin-bottom: 8px;
                }
                .title--container{
                    p{
                        font-family: Roboto, "sans-serif";
                        font-size: 17px;
                        color: #222222;
                        letter-spacing: 0;
                        line-height: 31px;

                    }
                }
            }


            .profile-form--container{
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5px;

                .body--container{
                    margin-top: 1px;

                    .input--container{
                        margin-top: 10px;
                        input{
                            width: 100%;
                        }
                    }
                }
            }

            .profile-form--container:first-child{
                margin-top: 0;
            }

            .password--container{
                margin-top: 17px;
                width: 100%;
                margin-left: auto;
                margin-right: auto;


                .body--container{
                    margin-top: 1px;

                    .input--container{
                        margin-top: 10px;
                        input{
                            width: 100%;
                        }
                    }
                }
            }
        }

        .controls--container{
            margin-top: 41px;
            display: flex;
            flex-direction: column;
            width: 335px;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            z-index: 6;



            a{
                margin-top: 25px;
                font-family: Roboto, "sans-serif";
                font-size: 13px;
                letter-spacing: 0px;
                cursor: pointer;
                z-index: 6;
            }
        }

        .button--container{
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;

            button{
                width: 100%;
            }

            @include for-desktop-devices{
                display: none;
            }
        }


    }

    .title--container{
        p{
            font-family: Roboto, "sans-serif";
            font-size: 17px;
            color: #222222;
            letter-spacing: 0;
            line-height: 20px;
        }
    }
</style>
